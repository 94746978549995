<template>
  <div>
    <div class="pa-0 ma-0">
      <v-card
        flat
        style="background-color: var(--v-background-base) !important"
        class="pa-0 ma-0"
      >
        <v-toolbar
          color="greyBase"
          dark
          :extended="!$vuetify.breakpoint.mobile"
          flat
          extension-height="55"
          class="pa-0 ma-0"
        >
          <v-toolbar-title>
            <b class="mr-3">FTP Configuration</b>
          </v-toolbar-title>
        </v-toolbar>

        <v-card
          class="mx-auto"
          :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
          :style="{
            'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
            'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
          }"
        >
          <v-card-text
            :style="{
              height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            }"
          >
            <v-row>
              <v-col cols="12" sm="4">
                <v-card
                  flat
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                  :loading="loading"
                >
                  <v-card-text>
                    <v-subheader
                      >Folders <v-spacer></v-spacer
                      ><v-btn @click="listObjects()" icon
                        ><v-icon>refresh</v-icon></v-btn
                      ></v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list dense>
                      <div class="scrollable-treeview">
                        <v-treeview
                          :active.sync="active"
                          :items="directoryListing"
                          :load-children="getSubfolders"
                          :open.sync="open"
                          hoverable
                          activatable
                          color="blue"
                          item-key="id"
                          transition
                          return-object
                        >
                          <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.id && !open" color="amber" > folder </v-icon>
                            <v-icon v-else-if="item.id && open" color="amber" > folder_open </v-icon>
                          </template>
                          <template v-slot:label="{ item }">
                            <div @contextmenu.prevent="viewMenu($event, item)">
                                <v-chip outlined style="border: none" >
                                    <div>{{ item.name }}</div>
                                </v-chip>
                            </div>
                          </template>
                        </v-treeview>
                      </div>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card
                  v-if="selectedFolder"
                  flat
                  :loading="loadingFolderConfig"
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
                  <v-card-title>
                    Selected Folder: {{ selectedFolder.name }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-list dense subheader>
                          <v-subheader style="font-size: 16px">
                            Credentials <v-spacer></v-spacer>
                            <v-tooltip top v-if="!item.secretId">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="secondary"
                                  icon
                                  :disabled="loadingFolderConfig"
                                  :loading="savingCredentials"
                                  @click="generateCredentials()"
                                  small
                                  ><v-icon>add</v-icon></v-btn
                                >
                              </template>
                              <span>Generate Credentials</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="blue"
                                  icon
                                  :disabled="loadingFolderConfig"
                                  :loading="savingCredentials"
                                  @click="
                                    confirmRegenerateCredentialsModal = true
                                  "
                                  small
                                  ><v-icon>refresh</v-icon></v-btn
                                >
                              </template>
                              <span>Regenerate Credentials</span>
                            </v-tooltip>

                            <!-- <v-tooltip top  v-if="item.secretId"> -->
                            <v-tooltip
                              top
                              v-if="item.username || item.password"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="red"
                                  icon
                                  @click="confirmRemoveCredentialsModal = true"
                                  small
                                  ><v-icon>delete</v-icon></v-btn
                                >
                              </template>
                              <span>Remove Credentials</span>
                            </v-tooltip>
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-action>
                              <v-icon color="grey">language</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.protocolHost">{{
                                  item.protocolHost
                                }}</span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Host
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row align="center">
                                <v-tooltip top v-if="item.protocolHost">
                                  <template v-slot:activator="{ on }">
                                    <template>
                                      <div class="text-center" v-on="on">
                                        <v-menu offset-y>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-chip
                                              style="mr-2"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{
                                                String(item.protocolHost)
                                                  .split(".")[0]
                                                  .toUpperCase()
                                              }}
                                            </v-chip>
                                          </template>
                                          <v-list>
                                            <v-list-item
                                              @click="
                                                selectHostProtocol(item.value)
                                              "
                                              v-for="(
                                                item, index
                                              ) in ftpSettings"
                                              :key="index"
                                            >
                                              {{ item.name }}
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                      </div>
                                    </template>
                                  </template>
                                  <span>Select Protocol</span>
                                </v-tooltip>

                                <v-tooltip top v-if="item.protocolHost">
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      small
                                      icon
                                      @click="copyData(`${item.protocolHost}`)"
                                      ><v-icon small
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Content</span>
                                </v-tooltip>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action>
                              <v-icon color="grey">person</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.username">{{
                                  item.username
                                }}</span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Username
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row align="center">
                                <v-tooltip top v-if="item.username">
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      small
                                      icon
                                      @click="copyData(item.username)"
                                      ><v-icon small
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Content</span>
                                </v-tooltip>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action>
                              <v-icon color="grey">lock</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.password">
                                  <div v-if="viewPassword">
                                    {{ item.password }}
                                  </div>
                                  <div v-else>*****************</div>
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Password
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row>
                                <v-tooltip top v-if="item.password">
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      small
                                      icon
                                      color="blue-grey"
                                      @click="viewPassword = !viewPassword"
                                      ><v-icon small v-if="!viewPassword"
                                        >visibility</v-icon
                                      ><v-icon small v-else
                                        >visibility_off</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>View/Hide Password</span>
                                </v-tooltip>

                                <v-tooltip top v-if="item.password">
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      small
                                      icon
                                      @click="copyData(item.password)"
                                      ><v-icon small
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Content</span>
                                </v-tooltip>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>

                          <v-list-item
                            v-if="item.username"
                            :disabled="loadingFolderConfig"
                            @click="
                              (ipAddressModal = true) &&
                                (ipAddress = item.ipAddress)
                            "
                          >
                            <v-list-item-action>
                              <v-icon color="grey">data_array</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.ipAddress">
                                  {{ item.ipAddress }}
                                </span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Whitelisted IP Address
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="
                              item.protocolHost === 'sftp.loglive.ai' &&
                              item.username
                            "
                            :disabled="loadingFolderConfig"
                            @click="
                              (publicKeyModal = true) &&
                                (publicKey = item.publicKey)
                            "
                          >
                            <v-list-item-action>
                              <v-icon color="grey">data_array</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.publicKey">
                                  *****************
                                </span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Public Key
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="
                              item.protocolHost === 'ftps.loglive.ai' &&
                              item.username
                            "
                            :disabled="loadingFolderConfig"
                            @click="
                              (tlsCertificateModal = true) &&
                                (tlsCertificate = item.tlsCertificate)
                            "
                          >
                            <v-list-item-action>
                              <v-icon color="grey">data_array</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span v-if="item.tlsCertificate">
                                  *****************
                                </span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                TLS Certificate (.crt file content)
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-list dense subheader>
                          <v-autocomplete
                            label="Linked Organisation"
                            outlined
                            dense
                            :loading="loadingOrganisations"
                            :items="organisations.data"
                            clearable
                            @change="saveCredentialSettings()"
                            item-text="relatedOrganisation.name"
                            item-value="relatedOrganisationId"
                            v-model="item.linkedOrganisationId"
                          >
                            <template v-slot:selection="data">
                              <v-list-item-avatar>
                                <v-avatar color="secondary">
                                  <h3 style="color: white">
                                    {{
                                      data.item.relatedOrganisation.name.charAt(
                                        0
                                      )
                                    }}
                                  </h3>
                                </v-avatar>
                              </v-list-item-avatar>
                              <span v-if="data.item">
                                {{ data.item.relatedOrganisation.name }}
                              </span>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-avatar>
                                <v-avatar color="secondary">
                                  <h3 style="color: white">
                                    {{
                                      data.item.relatedOrganisation.name.charAt(
                                        0
                                      )
                                    }}
                                  </h3>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  data.item.relatedOrganisation.name
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  data.item.relatedOrganisation.alias
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                          <div class="text-center">
                            <v-btn-toggle
                              outlined
                              v-model="configType"
                              rounded
                              color="blue"
                            >
                              <v-btn
                                outlined
                                small
                                @click="
                                  item.type == 'TEMPLATE'
                                    ? null
                                    : (item.type = 'TEMPLATE'),
                                    saveCredentialSettings()
                                "
                              >
                                <v-icon
                                  left
                                  :color="
                                    item.type == 'TEMPLATE' ? 'blue' : 'white'
                                  "
                                  >description</v-icon
                                >
                                <span style="text-transform: none; color: white"
                                  >Template</span
                                >
                              </v-btn>
                              <v-btn
                                small
                                outlined
                                @click="
                                  item.type == 'PALTRACK'
                                    ? null
                                    : (item.type = 'PALTRACK'),
                                    saveCredentialSettings()
                                "
                              >
                                <v-icon
                                  left
                                  :color="
                                    item.type == 'PALTRACK' ? 'blue' : 'white'
                                  "
                                  >widgets</v-icon
                                >
                                <span style="text-transform: none; color: white"
                                  >Paltrack File</span
                                >
                              </v-btn>

                              <v-btn
                                small
                                outlined
                                @click="
                                  item.type == 'OCR_DOCUMENT'
                                    ? null
                                    : (item.type = 'OCR_DOCUMENT'),
                                    saveCredentialSettings()
                                "
                              >
                                <v-icon
                                  left
                                  :color="
                                    item.type == 'OCR_DOCUMENT'
                                      ? 'blue'
                                      : 'white'
                                  "
                                  >article</v-icon
                                >
                                <span style="text-transform: none; color: white"
                                  >Document OCR</span
                                >
                              </v-btn>

                              <v-btn
                                small
                                outlined
                                @click="
                                  item.type == 'OCR_FINANCIAL'
                                    ? null
                                    : (item.type = 'OCR_FINANCIAL'),
                                    saveCredentialSettings()
                                "
                              >
                                <v-icon
                                  left
                                  :color="
                                    item.type == 'OCR_FINANCIAL'
                                      ? 'blue'
                                      : 'white'
                                  "
                                  >local_atm</v-icon
                                >
                                <span style="text-transform: none; color: white"
                                  >Financial OCR</span
                                >
                              </v-btn>
                            </v-btn-toggle>
                          </div>

                          <v-autocomplete
                            v-if="item.type == 'TEMPLATE'"
                            class="mt-2"
                            prepend-inner-icon="description"
                            label="Linked Template"
                            outlined
                            dense
                            :loading="loadingTemplates"
                            :items="templates"
                            clearable
                            @change="saveCredentialSettings()"
                            item-text="alias"
                            item-value="id"
                            v-model="item.stockFileMappingId"
                          ></v-autocomplete>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </div>

    <!-- Copied to clipBoard -->
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      absolute
      bottom
      color="blue"
      center
      text
    >
      Copied to clipboard!
    </v-snackbar>

    <!-- ipAddressModal -->
    <v-dialog v-model="ipAddressModal" width="800px" persistent>
      <v-card :loading="savingIPAddress">
        <v-subheader>
          Manage IP Address
          <v-spacer></v-spacer>
          <v-tooltip top open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                plain
                color="primary"
                @click="grabIPAddress()"
                :loading="savingWhitelist"
              >
                <v-icon>dns</v-icon>
              </v-btn>
            </template>
            <span> Grab Current IP Address </span>
          </v-tooltip>
          <v-tooltip top open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                plain
                color="secondary"
                @click="saveWhitelist()"
                :loading="savingWhitelist"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </template>
            <span> Save IP Address </span>
          </v-tooltip>
          <v-tooltip top open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                plain
                color="red"
                @click="ipAddressModal = false"
                >X</v-btn
              >
            </template>
            <span> Close </span>
          </v-tooltip>
        </v-subheader>
        <div style="padding: 0 12px">
          <v-card-subtitle style="padding: 0 0">
            Current Whitelisted IP Address: {{ item.ipAddress }}
          </v-card-subtitle>
          <v-text-field
            clearable
            outlined
            dense
            autofocus
            v-model="ipAddress"
            placeholder="Comma separated IP/Mask in CIDR format, for example '192.168.1.0/24,10.8.0.100/32'"
          ></v-text-field>
        </div>
      </v-card>
    </v-dialog>

    <!-- TLS Certificate Modal -->
    <v-dialog v-model="tlsCertificateModal" width="800px" persistent>
      <v-card :loading="savingTLSCertificate">
        <v-toolbar flat>
          <v-toolbar-title> Manage TLS Certificate </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="saveTLSCertificate()"
            :loading="savingTLSCertificate"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="tlsCertificateModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <textarea
            rows="4"
            cols="92"
            style="background-color: white; width: auto"
            v-model="tlsCertificate"
            placeholder="Comma separated TLS Certificates to have multiple TLS Certificate set up."
          ></textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Public Key Modal -->
    <v-dialog v-model="publicKeyModal" width="800px" persistent>
      <v-card :loading="savingPublicKey">
        <v-toolbar flat>
          <v-toolbar-title> Manage Public Key </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="savePublicKey()"
            :loading="savingPublicKey"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="publicKeyModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card-text>
            <v-card-text class="px-4, py-0">
              <v-text-field
                clearable
                outlined
                dense
                autofocus
                v-model="publicKey"
                placeholder="Comma separated public keys to have multiple public keys set up."
              ></v-text-field>
            </v-card-text>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- confirmRegenerate -->
    <v-dialog
      v-model="confirmRegenerateCredentialsModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmRegenerateCredentialsModal = false"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like to regenerate these credentials? The
            existing credentials will be overwritten and no longer usable.
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmRegenerateCredentialsModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="savingCredentials"
              @click="generateCredentials()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- removeCredentials -->
    <v-dialog
      v-model="confirmRemoveCredentialsModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmRemoveCredentialsModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like to remove these credentials?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmRemoveCredentialsModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="removingCredentials"
              @click="removeCredentials(item)"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- deletingFolder -->
    <v-dialog
      v-model="confirmDelete"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like delete this folder and all of its
            contents?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmDelete = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="deletingFolder"
              @click="deleteFolder"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- contextMenu -->
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item @click="addFolderModal = true">
          <v-list-item-action class="mr-1">
            <v-icon color="amber">create_new_folder</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Add Folder </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="selectedItem && selectedItem.name != '/'"
          @click="confirmDelete = true"
        >
          <v-list-item-action class="mr-1">
            <v-icon color="red">delete</v-icon>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title> Delete Folder </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- addFolderModal -->
    <v-dialog
      v-model="addFolderModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="selectedItem">
        <v-toolbar flat>
          <v-toolbar-title> Add Folder </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="!selectedItem.newName"
            color="secondary"
            @click="addFolder()"
            :loading="addingFolder"
            >
            <v-icon>save</v-icon>
            </v-btn >
          <v-btn text @click="addFolderModal = false">X</v-btn>
        </v-toolbar>
        <v-card-subtitle style="font-size: 12px" v-if="selectedItem.fullPath">
          Current path: {{ selectedItem.fullPath }}
        </v-card-subtitle>
        <v-card-text>
          <v-card-text>
            <v-text-field
              outlined
              dense
              autofocus
              v-model="selectedItem.newName"
              prefix="/"
              placeholder="Enter Folder Name"
            ></v-text-field>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectHost: null,
    selectHostDropdown: false,
    publicKeyModal: false,
    savingPublicKey: false,
    publicKey: null,
    tlsCertificateModal: false,
    savingTLSCertificate: false,
    tlsCertificate: null,
    addFolderModal: false,
    addingFolder: false,
    active: [],
    config: [],
    confirmDelete: false,
    confirmRemoveCredentialsModal: false,
    confirmRegenerateCredentialsModal: false,
    configType: 0,
    credentials: {},
    deletingFolder: false,
    directoryListing: [],
    editCredentialModal: false, // DUD
    ftpWhitelist: [],
    folderSearchQuery: undefined,
    ipAddress: null,
    ipAddressModal: false,
    item: {},
    loading: false,
    loadingFolderConfig: false,
    loadingOrganisations: false,
    loadingTemplates: false,
    open: [],
    organisations: {
      data: [],
      total: 0,
    },
    passwordKey: 100,
    removingCredentials: false,
    savingFolder: false,
    savingIPAddress: false,
    savingCredentials: false,
    savingWhitelist: false,
    selectedItem: {},
    showMenu: false,
    snackbar: false,
    templates: [],
    viewPassword: false,
    x: 0,
    y: 0,
    selectedHost: null,
    ftpSettings: [
      { name: "FTP", value: "ftp.loglive.ai" },
      { name: "FTPS", value: "ftps.loglive.ai" },
      { name: "SFTP", value: "sftp.loglive.ai" },
    ],
  }),
  watch: {
    async active(val) {
      if (val.length > 0) {
        // console.log("Active val", JSON.parse(JSON.stringify(val)));
        await this.getFolderConfig(val.at(-1));
      }
    },
  },
  computed: {
    selectedFolder() {
      if (this.active.length == 0) return;
      return this.active.at(-1);
    },
  },
  created() {
    this.getFTPConfig();
    this.listObjects();
    this.getFileTemplates();
    this.getOrganisations();
  },
  methods: {
    async grabIPAddress() {
      try {
        this.savingIPAddress = true;
        this.ipAddress = this.item.ipAddress;
        let result = await this.$API.getFTPGrabIPAddress();
        let newIPString;
        if (this.ipAddress != null) {
          newIPString = this.ipAddress + "," + result.ip + "/32";
          // console.log(newIPString)
        } else {
          newIPString = result.ip + "/32";
          // console.log(newIPString)
        }
        this.ipAddress = newIPString;
      } catch (e) {
        this.$message.error("FAILURE ERROR OCCURED");
        return console.log(e);
      } finally {
        this.savingIPAddress = false;
      }
    },

    async saveWhitelist() {
      // updateIPSFTPGo() SFTPGo
      try {
        this.savingIPAddress = true;
        this.ipAddressModal = true;
        this.item.ipAddress = this.ipAddress;
        this.item.oldUsername = this.item.username;
        let buffer = await this.$API.updateSFTPGoUser(this.item);
        let result = await this.$API.updateFTPCredentials({
          ...this.item,
        });
      } catch (e) {
        this.$message.error("FAILURE ERROR OCCURED");
        this.item.ipAddress = null;
        return;
      } finally {
        this.savingIPAddress = false;
        this.ipAddressModal = false;
        this.ipAddress = null;
      }
    },

    async selectHostProtocol(protocol) {
      try {
        this.loadingFolderConfig = true;
        this.item.oldUsername = this.item.username;
        this.item.protocolHost = protocol;

        let result = await this.$API.updateFTPCredentials({
          ...this.item,
        });
        if (result) {
          this.$message.success("Update Of Folder Host SUCCESSFUL");
        }
        return;
      } catch (e) {
        this.item.protocolHost = null;
        this.$message.error("FAILURE ERROR OCCURED");
        return console.log(e);
      } finally {
        this.loadingFolderConfig = false;
        this.selectHostDropdown = false;
      }
    },

    async saveTLSCertificate() {
      this.savingTLSCertificate = true;
      try {
        this.item.tlsCertificate = this.tlsCertificate;
        this.item.oldUsername = this.item.username;
        let result = await this.$API.updateFTPCredentials({
          ...this.item,
        });
        let buffer = await this.$API.updateSFTPGoUser(this.item);
        return;
      } catch (e) {
        console.log(e);
        this.item.tlsCertificate = null;
        this.$message.error("FAILURE ERROR OCCURED");
        this.getFTPConfig();
        return;
      } finally {
        this.savingTLSCertificate = false;
        this.tlsCertificateModal = false;
      }
    },

    async savePublicKey() {
      this.savingPublicKey = true;
      try {
        this.item.publicKey = this.publicKey;
        this.item.oldUsername = this.item.username;
        let result = await this.$API.updateFTPCredentials({
          ...this.item,
        });
        let buffer = await this.$API.updateSFTPGoUser(this.item);
      } catch (e) {
        this.$message.error("FAILURE ERROR OCCURED");
        this.item.publicKey = null;
        this.getFTPConfig();
        return;
      } finally {
        this.publicKey = null;
        this.savingPublicKey = false;
        this.publicKeyModal = false;
      }
    },

    copyData(data) {
      navigator.clipboard.writeText(data);
      this.snackbar = true;
    },

    async addFolder() {
      this.addingFolder = true;
      try {
        let isValidFolderName = !/[*+?^${}()|[\]\\]/g.test(
          this.selectedItem.newName
        );
        if (!isValidFolderName) {
          return this.$message.error("Invalid Folder Name Used");
        }
        if (this.selectedItem.fullPath != "/") {
          let result = await this.$API.createFTPFolder({
            path: this.selectedItem.fullPath + this.selectedItem.newName + "/",
          });
          this.getSubfolders(this.selectedItem);
        } else {
          let result = await this.$API.createFTPFolder({
            path: this.selectedItem.fullPath + this.selectedItem.newName + "/",
          });
          this.listObjects();
        }
        return;
      } catch (e) {
        this.$message.error("FAILED");
        return;
      } finally {
        this.selectedItem.newName = null;
        this.addingFolder = false;
        this.addFolderModal = false;
      }
    },

    async deleteFolder() {
      // @contextMenu
      this.deletingFolder = true;
      try {
        let childFolders = await this.$API.getFTPChildFolders(
          this.selectedItem
        );

        for (let i = 0; i < childFolders.length; i++) {
          const folder = childFolders[i];
          // console.log(folder);
          if (folder.username) {
            let result = await this.removeCredentials(folder);
          }
          await this.$API.deleteFTPFolder({ path: folder.path });
        }

        await this.$API.deleteFTPFolder({ path: this.selectedItem.fullPath });

        // let findPath = this.selectedFolder.children.findIndex(x=>x.fullPath == this.selectedItem.fullPath);
        // console.log("Index", findPath);
        // this.selectedFolder.children.splice(findPath, 1);
        // this.deletingFolder = false;
        this.listObjects();
        return this.$message.success("Successfully deleted folder!");
      } catch (e) {
        console.log(e);
        return this.$message.error("FAILURE OCCURED");
      } finally {
        this.deletingFolder = false;
        this.confirmDelete = false;
      }
    },

    async generateCredentials() {
      this.savingCredentials = true;
      try {
        if (this.item.username) {
          let result = await this.$API.deleteSFTPGoUser({
            username: this.item.username,
          });
          this.item.username = null;
        }
        this.item.ipAddress = null;
        this.item.tlsCertificate = null;
        this.item.publicKey = null;
        this.item.username = await this.$API.generateFTPUsername({
          length: 12,
        });
        this.generatePassword(32);
        this.item = await this.$API.updateFTPCredentials(this.item);
        let result = await this.$API.createSFTPGoUser(this.item);
        return;
      } catch (e) {
        return this.$message.error("FAILURE OCCURED");
      } finally {
        this.savingCredentials = false;
        this.confirmRegenerateCredentialsModal = false;
      }
    },
    generatePassword(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      this.item.password = result;
      this.passwordKey++;
    },

    async getFolderConfig(data) {
      // WATCH()
      this.loadingFolderConfig = true;
      this.item = {};
      if (this.folderSearchQuery) {
        this.folderSearchQuery.abort();
      }
      this.folderSearchQuery = new AbortController();
      const signal = this.folderSearchQuery.signal;
      let result = await this.$API.getFTPFolderConfig(data.fullPath, signal);
      if (result) {
        this.item = result;
      } else {
        // console.log(data);
        let result = await this.$API.createFTPFolderConfig({
          path: data.fullPath,
          folder: data.name,
          ftpWhitelists: [],
        });
        // console.log("Create result", result);
        this.item = result;
      }
      this.configType =
        this.item.type == "PALTRACK"
          ? 1
          : this.item.type == "TEMPLATE"
          ? 0
          : this.item.type == "OCR_DOCUMENT"
          ? 2
          : this.item.type == "OCR_FINANCIAL"
          ? 3
          : null;
      this.loadingFolderConfig = false;
    },

    async getFTPConfig() {
      // CREATED()
      this.config = await this.$API.getFTPConfig();
      // console.log(this.config)
    },

    async getFileTemplates() {
      // CREATED()
      this.loadingTemplates = true;
      this.templates = await this.$API.getFileMappings();
      this.loadingTemplates = false;
    },

    async getOrganisations() {
      // CREATED()
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelation({
        // params: this.params, // undefined
      });
      this.loadingOrganisations = false;
    },

    async getSubfolders(data) {
      let result = await this.$API.getFTPRoutes({
        params: { folder: data.fullPath },
      });
      data.children = result;
    },

    async listObjects() {
      // CREATED()
      this.loading = true;
      this.directoryListing = [];
      this.directoryListing = await this.$API.getFTPRoutes({});
      this.active = [this.directoryListing[0]];
      this.loading = false;
    },

    async removeCredentials(data) {
      // deleteUserSFTPGo() SFTPGo
      this.removingCredentials = true;
      try {
        if (data.username) {
          await this.$API.deleteSFTPGoUser({
            username: data.username,
          });
          data.username = null;
        }
        data.password = null;
        data.protocolHost = "ftp.loglive.ai";
        data.ipAddress = null;
        data.publicKey = null;
        data.tlsCertificate = null;
        data.type = null;
        let result = await this.$API.updateFTPCredentials(data);
      } catch (e) {
        this.$message.error("FAILED");
        return;
      } finally {
        this.removingCredentials = false;
        this.confirmRemoveCredentialsModal = false;
      }
    },

    async saveCredentialSettings() {
      this.savingCredentials = true;
      this.item.oldUsername = this.item.username;
      let result = await this.$API.updateFTPCredentials({
        ...this.item,
      });
      this.$message.success("Successfully updated credentials!");
      this.savingCredentials = false;
    },

    viewMenu(event, item) {
      this.x = event.clientX;
      this.y = event.clientY;
    //   console.log(item);
      this.selectedItem = item;
      this.showMenu = true;
    },
  },
};
</script>
<style scoped>
.scrollable-treeview {
  max-height: 76vh; /* Set desired height */
  overflow-y: auto; /* Enable vertical scrolling */
}
</style>